import React, { Component } from 'react';
import { Link } from "react-router-dom";
class MainServices extends Component {
	constructor(props) {
        super(props);
        this.state = {
            titleServices: [
                {
                    id: 1,
					title: 'What We Can Offer You',
					// description: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor <br> incididunt ut labore et dolore magna aliqua.',
                }
			],
			imagebox: [
				{
					id: '1',
					imgsrc: 'images/imagebox/04.jpg',
					title: 'HOSTING FOR MINERS',
					description: 'Secure hosting for commercial and personal mining operations at a per KWH fee basis.'
				},
				{
					id: '2',
					imgsrc: 'images/imagebox/05.jpg',
					title: 'HOSTING FOR CHIA FARM',
					description: 'Secure hosting for Chia plots for Chia farming on a 3-month pre-paid term for allocated terabytes.'
				},
				{
					id: '3',
					imgsrc: 'images/imagebox/06.jpg',
					title: 'CHIA PLOT GENERATION',
					description: 'Yams will be constantly plotting with its in-memory plotters allowing sales of plots.'
				},
				{
					id: '4',
					imgsrc: 'images/imagebox/07.jpg',
					title: 'HOSTING FOR ASIC MINING',
					description: 'Excess facility space and energy production can be used for hosting ASIC mining.'
				},
				{
					id: '5',
					imgsrc: 'images/imagebox/08.jpg',
					title: 'NFT & SMART-CONTRACTS',
					description: 'YAMS Harvest will offer development services for NFT and Smart-contract development.'
				},
				{
					id: '9',
					imgsrc: 'images/imagebox/09.jpg',
					title: 'CLOUD STORAGE',
					description: 'Excess drive space can be offered as cloud storage to clients. '
				}
			]
        }
    }
    render() {
        return (
			<section className="flat-row pd-services-post">
				<div className="container">
					<div className="row">
						<div className="col-md-12">
							{
								this.state.titleServices.map(data =>(
									<div className="title-section center s1" key={data.id} >
										<h2>{data.title} </h2>
										<p className="sub-title-section">{data.description} </p>
									</div>
								))
							}
							<div className="dividers dividers-imagebox"></div>
						</div>
					</div>
					<div className="row">
						<div className="col-md-12">
							<div className="wrap-imagebox-grid">
									{
										this.state.imagebox.map(data =>(
											<div className="flat-imagebox services-grid item" key={data.id} >
												<div className="flat-imagebox-inner"  >
													<div className="flat-imagebox-image">
														<img src={data.imgsrc} alt="img" />
													</div>
													<div className="flat-imagebox-header center">
														<h3 className="flat-imagebox-title">
															<Link to="#">{data.title}</Link>	
														</h3>
													</div>
													<div className="flat-imagebox-content">
														<div className="flat-imagebox-desc center">{data.description}</div>
														{/* <div className="flat-imagebox-button">
															<Link to="#" target="_blank">Read More</Link>
														</div> */}
													</div>
												</div>
											</div> 
								
										))
									} 
							</div>
						</div>
					</div>
				</div>
			</section>

				



        );
    }
}

export default MainServices;