import React, { Component } from 'react';
import ServicesBox1 from './ServicesBox1'
import ServicesBox2 from './ServicesBox2'
class Summary extends Component {
	constructor(props) {
        super(props);
        this.state = {
            titleServices: [
                {
                    id: 1,
                    title: 'Visions',
                }
            ]
        }
    }
    render() {
        return (
            <section className="flat-row flat-iconbox bg-theme">
				<div className="container">

					<div className="row" >
						{
							this.state.titleServices.map(data => (
								<div className="col-md-12" key={data.id}>
									<div className="title-section left">
										<h2>{data.title}</h2>
									</div>
									{/* <!-- /.title-section --> */}
								</div>

							))
						}
						
					</div>
					{/* <!-- /.row --> */}
					{/* <ServicesBox1 /> */}
						<h2>Energy is everything in the mining industry, however, being energy efficient only results in profitable revenue. Strategic analysis and targeted asset acquisition results in high profitability. Yams analysts are industry seasoned with successful asset performance forecasts based on expertise in trading, technology, and industry insight. </h2>
					
					{/* <ServicesBox2 /> */}
				</div>
        	</section>
        // <!-- /.flat-row-iconbox -->

     
           
        );
    }
}

export default Summary;