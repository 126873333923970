import React, { Component } from 'react';
import { withRouter} from 'react-router-dom'
import { Footer,Header,Slider,  Loader,CarouselClient,  TopBar } from '../layouts/general';
import { Featured } from '../layouts/general/featured';
import { Mission } from '../layouts/general/mission';
import { Summary } from '../layouts/general/summary';
import { Vission } from '../layouts/general/vission';
import { ServiceOverview } from '../layouts/general/service_overview';
import { Project } from '../layouts/general/projects';
import { Callback } from '../layouts/general/callback';
import { Blog } from '../layouts/general/blog';

class Home01 extends Component {
    constructor(props) {
        super(props);
        this.state = {
           headers: [
               {
                   id: 1,
                   names: 'Home'
               }
           ],
           
        }
    }
    render() {
        return (
            <div className="bg-body3">
                <div className="boxed">
                    <Loader />
                    {
                        this.state.headers.map(data => (
                            <Header data={data} key={data.id}/>
                        ))
                    }
                    <Slider />
                    <Summary />
                    <Vission />
                    <Mission />
                    <Footer />
                </div>
            </div>
        );
    }
}

export default withRouter(Home01);