import React, { Component } from 'react';
import ServicesBox1 from './ServicesBox1'
import ServicesBox2 from './ServicesBox2'
class Summary extends Component {
	constructor(props) {
        super(props);
        this.state = {
            titleServices: [
                {
                    id: 1,
                    title: 'Summary',
                }
            ]
        }
    }
    render() {
        return (
            <section className="flat-row flat-iconbox bg-theme">
				<div className="container">

					<div className="row" >
						{
							this.state.titleServices.map(data => (
								<div className="col-md-12" key={data.id}>
									<div className="title-section left">
										<h2>{data.title}</h2>
									</div>
									{/* <!-- /.title-section --> */}
								</div>

							))
						}
						
					</div>
					{/* <!-- /.row --> */}
					{/* <ServicesBox1 /> */}
						<h2>YAMS delivers industry leading services to our clients by leveraging our development team to deliver real-time proprietary data to our seasoned digital asset analysts. The synergy between analyst, data scientist and developer gives YAMS the cutting edge technology, resources and human capital to outperform our competitors. </h2>
					
					{/* <ServicesBox2 /> */}
				</div>
        	</section>
        // <!-- /.flat-row-iconbox -->

     
           
        );
    }
}

export default Summary;