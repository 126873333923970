const menus = [
    {
        id: 1,
        name: 'Home',
        linkmenu: '/',
        namesub: [
            {
                id: 1,
                sub: 'Home Classic Header',
                links: '/',
                submenu: undefined
            },
            // {
            //     id: 2,
            //     sub: 'Home Widget Header',
            //     links: '/index-v3',
            //     submenu: undefined
            // },
            // {
            //     id: 3,
            //     sub: 'Home Modern Header',
            //     links: '/index-v2',
            //     submenu: undefined
            // },
            // {
            //     id: 4,
            //     sub: 'Home Transparent Header',
            //     links: '/index-v4',
            //     submenu: undefined
            // },
            // {
            //     id: 5,
            //     sub: 'Home Layout 02',
            //     links: 'index-layout2',
            //     submenu: undefined
            // },
            // {
            //     id: 6,
            //     sub: 'Home Layout 03',
            //     links: '/index-v5',
            //     submenu: undefined
            // }
        ]
    },
    // {
    //     id: 2,
    //     name: 'About',
    //     linkmenu: '/about-v1',
    //     namesub: [
    //         {
    //             id: 1,
    //             sub: 'Our Team',
    //             links: '/about-v1',
    //             submenu: undefined
    //         },
    //         {
    //             id: 2,
    //             sub: 'Team Grid',
    //             links: '/about-v2',
    //             submenu: undefined
    //         },
    //         {
    //             id: 3,
    //             sub: 'Overview',
    //             links: '/about-v3',
    //             submenu: undefined
    //         }
    //     ],
    // },
    {
        id: 3,
        name: 'Services',
        linkmenu: '/services',
        namesub:  [
            {
                id: 1,
                sub: 'Services Grid',
                links: '/services',
                submenu: undefined
            },
            // {
            //     id: 2,
            //     sub: 'Risk Management',
            //     links: '/services-v2',
            //     submenu: undefined
            // }
        ],
    },
    // {
    //     id: 4,
    //     name: 'Portfolio',
    //     linkmenu: '/portfolio-v3',
    //     namesub: [
    //         {
    //             id: 1,
    //             sub: 'Portfolio Default',
    //             links: '/portfolio-v3',
    //             submenu: undefined
    //         },
    //         {
    //             id: 2,
    //             sub: 'Layout 02',
    //             links: '/portfolio-v2',
    //             submenu: undefined
    //         },
    //         {
    //             id: 3,
    //             sub: 'Portfolio Load More',
    //             links: '/portfolio-v1',
    //             submenu: undefined
    //         }
    //     ],
    // },
    // {
    //     id: 5,
    //     name: 'News',
    //     linkmenu: '/blog',
    //     namesub: [
    //         {
    //             id: 1,
    //             sub: 'Blog',
    //             links: '/blog',
    //             submenu: undefined
    //         },
    //         {
    //             id: 2,
    //             sub: 'Blog Grid',
    //             links: '/blog-grid',
    //             submenu: undefined
    //         },
    //         {
    //             id: 3,
    //             sub: 'Blog Single',
    //             links: '/blog-single',
    //             submenu: undefined
    //         },
            

    //     ],
    // },
    // {
    //     id: 6,
    //     name: 'Contact',
    //     linkmenu: '/contacts',
    //     namesub: [
    //         {
    //             id: 1,
    //             sub: 'Contact 01',
    //             links: '/contacts',
    //             submenu: undefined
    //         },
    //         {
    //             id: 2,
    //             sub: 'Contact 02',
    //             links: '/contact-v2',
    //             submenu: undefined
    //         },
            

    //     ],
    // }
]

export default menus;