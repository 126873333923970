import React, { Component } from 'react';
class Mission extends Component {
	constructor(props) {
        super(props);
        this.state = {
            titleServices: [
                {
                    id: 1,
                    title: 'Mission',
                }
            ]
        }
    }
    render() {
        return (
            <section className="flat-row flat-iconbox bg-theme">
				<div className="container">

					<div className="row" >
						{
							this.state.titleServices.map(data => (
								<div className="col-md-12" key={data.id}>
									<div className="title-section left">
										<h2>{data.title}</h2>
									</div>
									{/* <!-- /.title-section --> */}
								</div>

							))
						}
						
					</div>
					{/* <!-- /.row --> */}
						<h2>While Yams vision and business objectives are initially focused on efficient architecture and strategic asset acquisition for rapid realization of capital gains, its mission is to emerge as a scalable solution both internally and as a service provision to combat fossil fuel consumption through the introduction of energy efficient mining as a service.</h2>
					
				</div>
        	</section>
        // <!-- /.flat-row-iconbox -->

     
           
        );
    }
}

export default Mission;