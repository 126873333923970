import React, { Component } from 'react';
import ServicesBox1 from './ServicesBox1'
import ServicesBox2 from './ServicesBox2'
class ServiceOverview extends Component {
	constructor(props) {
        super(props);
        this.state = {
            titleServices: [
                {
                    id: 1,
                    title: 'Service Overview',
                }
            ]
        }
    }
    render() {
        return (
            <section className="flat-row flat-iconbox bg-theme">
				<div className="container">

					<div className="row" >
						{
							this.state.titleServices.map(data => (
								<div className="col-md-12" key={data.id}>
									<div className="title-section left">
										<h2>{data.title}</h2>
									</div>
									{/* <!-- /.title-section --> */}
								</div>

							))
						}
						
					</div>
					{/* <!-- /.row --> */}
					{/* <ServicesBox1 /> */}
						<h2>YAMS provides mining, hosting and cloud solutions from a custom built facility in Las Vegas Nevada. Our extensive product knowledge and rigorous attention to detail allow us to provide the lowest possible kilowatt hour costs to our clients. </h2>
					
					{/* <ServicesBox2 /> */}
				</div>
        	</section>
        // <!-- /.flat-row-iconbox -->

     
           
        );
    }
}

export default ServiceOverview;